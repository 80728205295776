<template>
  <div class="joinOur">
 <div class="head-box">
  <div class="head">
      <!-- 掌动科技&ensp;&ensp;快乐生活 -->
<img :src="windowWidth>992?banner.pcBanner:banner.mbBanner" alt="">
    </div>
 </div>
  <div class="content-box">
    <div class="content">
      <div class="content-one">
        <div class="content-one-box">
          <div class="content-one-title">商务合作</div>
          <div class="content-one-line"></div>
          <div class="content-one-describe">
            <!-- 欢迎广大业内企业、媒体等与我们接洽产品合作事宜，努力打造精品，共创双赢局面。 -->
            联系人：杨先生
          </div>
          <!-- <div class="content-one-contact">联系人：杨先生</div> -->
          <div class="content-one-phone">
            <div>QQ：</div>
            <div>3204450258</div>
          </div>
          <div class="content-one-others">
            <div>邮箱：</div>
            <div>yangchao@zdjoys.com</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-bottom">
      <div class="content-one">
        <div class="content-one-box">
          <div class="content-one-title">联系方式</div>
          <div class="content-one-line"></div>
          <div class="content-one-describe-item">杭州掌动科技股份有限公司</div>
          <div class="content-one-describe-item">
            公司地址：浙江省杭州市滨江区滨盛路1777号19楼
          </div>

          <div class="content-one-describe-item">
            公司电话：（0571）56690777
          </div>

        </div>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
// import Bottom from '../components/bottom.vue'
export default {
  data () {
    return {
      windowWidth: document.documentElement.clientWidth,
      banner: {
        pcBanner: require('@/assets/lunbo/lianxi.png'),
        mbBanner: require('@/assets/lunbo/m_lianxi.png')
      }
    }
  },
  components: {
    // Bottom
  },
  created () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 750px) {
  .joinOur {
    padding-top: 50px;

   .head-box{
    display: flex;
    justify-content: center;
    .head {
      height: 150px;
      width: 100%;

      img{
        width: 100%;
        height: 100%;
      }
    }
   }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      //  color: #4c5152;

      .content-one {
        width: 90%;
        border-bottom: 1px solid #b9bac1;
        display: flex;
        justify-content: center;

        .content-one-box {
          height: 250px;
          width: 300px;
          // width: 1200px;
          // background-color: aqua;

          // display: flex;
          // justify-content: center;
          // align-items: center;
          padding-top: 20px;

          .content-one-title {
            font-size: 20px;
            color: #fe8007;
          }

          .content-one-line {
            width: 36px;
            height: 2px;
            background-color: #fe8007;
            margin: 5px 0;
          }

          .content-one-describe {
            font-size: 14px;
            margin: 10px 0 15px 0;
          }

          .content-one-contact {
            font-size: 14px;
            margin: 10px 0;
          }

          .content-one-phone {
            margin: 15px 0 5px 0;
            // font-size: 14px;
            // color: #b9bac1;

            display: flex;
            height: 30px;
            // background-color: #fe8007;
            align-items: center;
            font-size: 14px;
            div:nth-child(1){
              height: 20px;
              // background-color: aqua;
              line-height: 20px;

            }
            div:nth-child(2){
              height: 20px;
              // background-color: red;
              margin-top: 1px;
              line-height: 20px;
            }
            // span{

            //   display: inline-block;
            //   font-size: 18px;
            //   padding-top: 2px;
            // }

          }

          .content-one-others {
            // margin: 10px 0;
            display: flex;
            height: 30px;
            // background-color: #fe8007;
            align-items: center;
            font-size: 14px;
            div:nth-child(1){
              height: 20px;
              // background-color: aqua;
              line-height: 20px;

            }
            div:nth-child(2){
              height: 20px;
              // background-color: red;
              margin-top: -3px;
              line-height: 20px;
            }
            // span{

            //   display: inline-block;
            //   font-size: 18px;
            //   padding-top: 2px;
            // }
          }

          .iconfont {
            font-size: 16px;
            display: inline-block;
            vertical-align: middle;
          }

          // .content-one-box {}
        }
      }
    }

    .content-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #4d4d4e;

      .content-one {
        width: 100%;
        // border-bottom: 1px solid #b9bac1;
        display: flex;
        justify-content: center;

        .content-one-box {
          height: 250px;
          width: 300px;
          // width: 1200px;
          // background-color: aqua;

          // display: flex;
          // justify-content: center;
          // align-items: center;
          padding-top: 20px;

          .content-one-title {
            font-size: 20px;
            color: #fe8007;
          }

          .content-one-line {
            width: 36px;
            height: 3px;
            background-color: #fe8007;
            margin: 5px 0 10px 0;
          }

          .content-one-describe {
            font-size: 14px;
            // margin: 15px 0 20px 0;
          }

          .content-one-describe-item {
            font-size: 14px;
            margin: 5px 0;
          }

          // .content-one-box {}
        }
      }
    }
  }
}

// pc端
@media (min-width: 992px) {
  .joinOur {
    padding-top: 50px;

    .head-box{
      .head {
      height: 400px;
      width: 1400px;
      margin: 0 auto;
      img{
        width: 100%;
        height: 100%;
      }
      // background: url("../assets/updateBanner/pcAbout.png") no-repeat no-repeat;
      // background-size: cover;
      // font-size: 50px;
      // text-align: center;
      // line-height: 400px;
      // color: #ffffff;
    }
    }

    .content-box{
      .content {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      color: #4d4d4e;

      .content-one {
        // width: 90%;
        // display: flex;
        // justify-content: center;

        .content-one-box {
          height: 350px;
          width: 1200px;
          margin: 0 auto;
        border-bottom: 1px solid #b9bac1;

          // background-color: aqua;

          // display: flex;
          // justify-content: center;
          // align-items: center;
          padding-top: 80px;

          .content-one-title {
            font-size: 40px;
            color: #fe8007;
          }

          .content-one-line {
            width: 75px;
            height: 3px;
            background-color: #fe8007;
            margin: 5px 0;
          }

          .content-one-describe {
            font-size: 18px;
            margin: 30px 0 25px 0;
          }

          .content-one-contact {
            font-size: 16px;
            margin-bottom: 20px;
          }

          .content-one-phone {
            display: flex;
            height: 40px;
            // background-color: #fe8007;
            align-items: center;
            font-size: 16px;
            div:nth-child(1){
              height: 20px;
              // background-color: aqua;
              line-height: 20px;

            }
            div:nth-child(2){
              height: 20px;
              // background-color: red;
              margin-top: 1px;
              line-height: 20px;
            }
            // span{

            //   display: inline-block;
            //   font-size: 18px;
            //   padding-top: 2px;
            // }
          }

          .content-one-others {
            display: flex;
            height: 40px;
            // background-color: #fe8007;
            align-items: center;
            font-size: 16px;
            div:nth-child(1){
              height: 20px;
              // background-color: aqua;
              line-height: 20px;

            }
            div:nth-child(2){
              height: 20px;
              // background-color: red;
              margin-top: -3px;
              line-height: 20px;
            }
            // span{

            //   display: inline-block;
            //   font-size: 18px;
            //   padding-top: 2px;
            // }
          }

          .iconfont {
            font-size: 20px;
          }

          // .content-one-box {}
        }
      }
    }

    .content-bottom {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      color: #4d4d4e;

      .content-one {
        width: 100%;
        // border-bottom: 1px solid #b9bac1;
        // display: flex;
        // justify-content: center;

        .content-one-box {
          height: 350px;
          width: 1200px;
          margin: 0 auto;
          // background-color: aqua;

          // display: flex;
          // justify-content: center;
          // align-items: center;
          padding-top: 40px;
          // margin-bottom: 30px;

          .content-one-title {
            font-size: 40px;
            color: #fe8007;
          }

          .content-one-line {
            width: 75px;
            height: 3px;
            background-color: #fe8007;
            margin: 5px 0 30px 0;
          }

          .content-one-describe-item {
            font-size: 18px;
            margin: 15px 0;
          }
        }
      }
    }
    }

  }
}
</style>
